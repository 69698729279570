import axios from 'axios';

export const fetchLocation = async () => {
  try {
    const response = await axios.get(`https://ipapi.co/json/`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération de la localisation', error);
    throw error;
  }
};
