import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import styles from './call-to-action.module.scss';
import useLocalStorage from '../../hooks/useLocalStorage';

/**
 * Call To Action on results page
 *
 * @returns {React.ReactElement}
 * @see resultats.js
 */
function CallToAction() {
  const [scoreObject] = useLocalStorage('score', null);

  useEffect(() => {
    if (scoreObject) {
      console.log('scoreObject updated:', scoreObject);
    }
  }, [scoreObject]);

  function addToCart() {
    if (!scoreObject || typeof scoreObject !== 'object') {
      console.error('Invalid or undefined scoreObject:', scoreObject);
      return;
    }

    let listOptions = [];

    for (const [key, value] of Object.entries(scoreObject)) {
      if (value >= 20 && key !== 'total') {
        listOptions.push(key);
      }
    }

    const products = listOptions.sort().join(',');
    const productList = {
      antiox: 'antiox',
      'antiox,coaching': 'antiox_plus',
      coaching: 'essentiel_plus',
      'coaching,poids': 'equilibre_plus',
      poids: 'equilibre',
      'antiox,poids': 'complet',
      'antiox,coaching,poids': 'absolu',
    };

    const name = productList[products] || 'essentiel';

    navigate(`/panier/?product=${name}`);
  }

  return (
    <div className={`Center ${styles.cta}`}>
      <button onClick={addToCart} className={`Btn Btn--Black ${styles.button}`}>
        AJOUTER AU PANIER
      </button>
    </div>
  );
}

export default CallToAction;
