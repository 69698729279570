import React, { useEffect, useState } from 'react';
import { StaticQuery, graphql, navigate } from 'gatsby';
import { Formik, Form, Field } from 'formik';
import ReactTooltip from 'react-tooltip';
import formStyle from './repeatable/modules/form.module.scss';
import axios from 'axios';
import CartContext, { products } from '../context/CartContext';
import * as Sentry from '@sentry/gatsby';
import { useLocation } from '@reach/router';
import useLocalStorage from '../hooks/useLocalStorage';
import useFacebookConversionApi from '../hooks/useFacebookConversionApi';
import AddressAuto from './checkout-address-auto';
import CartItem from './panier/cart-item';
import style from '../modules/checkout.module.scss';
import ParcelShopWidget from './parcelShopWidget';
import Modal from 'react-modal';

function Checkout({ data }) {
  const {
    pageCheckoutYaml: {
      client,
      source,
      shipping,
      country,
      same_billing,
      relay_point,
      billing,
      billing_country,
      consent,
      button,
    },
  } = data;

  const [subscribedToMarketing, setSubscribedToMarketing] = React.useState(false);
  const [isFixed, setFixed] = React.useState(false);
  const [validEmail, setValidEmail] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [loading, setLoader] = React.useState(false);
  const [address, setAddress] = React.useState(null);
  const { cart } = React.useContext(CartContext);
  const location = useLocation();
  const inputs = client.inputs.concat(billing.inputs, shipping.inputs);
  const [selectedValue, setSelectedValue] = useState('');
  const [shippingOption, setShippingOption] = useState(false);
  const [selectPoint, setSelectPoint] = useState(null);
  const [showModal, setSetShowModal] = useState(false);
  const [relayPointId, setRelayPointId] = useState(null);

  let initialValues = {};

  for (let i = 0; i < inputs.length; i++) {
    const name = inputs[i].name;
    if (name) initialValues[name] = '';
  }

  initialValues.same_billing = true;
  initialValues.country = 'France';
  initialValues.billing_country = 'France';

  initialValues.consent_cgv = false;
  initialValues.consent_ppd = false;
  initialValues.pickupId = '';
  initialValues.pickupAddress = '';

  //const [storedCheckoutForm, setStoredCheckoutForm] = useSessionStorage('checkoutForm', initialValues);

  const [fertilityTestResults] = useLocalStorage('answers', null);
  const [candidateCoupon] = useLocalStorage('candidateCoupon', null);

  /**
   * Set by google tag manager
   * Used by Facebook Business Manager to deduplicate events
   * @see https://www.facebook.com/business/help/823677331451951?id=1205376682832142
   */
  const [eventId] = useLocalStorage('eventId', null);

  const email = fertilityTestResults?.email || '';
  const firstname = fertilityTestResults?.firstname || '';
  const phone = fertilityTestResults?.phone || '';
  const contentIds = cart?.contents?.items.map((item) => item.id) || [];

  useFacebookConversionApi('PageView', location.href, email, firstname, phone, eventId);
  const [fbEventName, setFbEventName] = React.useState('InitiateCheckout');
  useFacebookConversionApi(fbEventName, location.href, email, firstname, phone, eventId, cart.total, contentIds);

  /**
   * Check wether input is empty
   * @param {*} input
   */
  function isEmpty(input) {
    const type = typeof input;
    switch (type) {
      case 'string':
        return input === '';
      case 'number':
        return input === 0;
      case 'object':
        if (Array.isArray(input)) return input.length === 0;
        return false;
      case 'boolean':
        return input;
      default:
        return true;
    }
  }

  /**
   * Validate email
   */
  async function validateEmail(email) {
    if (validEmail === email) return;
    let error;
    if (isEmpty(email)) {
      error = 'Champ requis';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      error = 'Email non valide';
    } else {
      const endpoint = `${process.env.GATSBY_API}/patients/check-email/${email}`;
      try {
        await axios.get(endpoint);
      } catch (e) {
        if (e.response && e.response.status === 409) error = 'Cette adresse email est déjà associée à un compte BeMum';
      }
    }
    if (!error) setValidEmail(email);
    return error;
  }

  /**
   * Validate address
   */
  async function validateAddress(address) {
    let error;
    if (isEmpty(address)) {
      error = 'Champ requis';
    } else if (
      !/^[0-9\\\/# ,a-zA-Z]+[ ,]+[0-9\\\/#, a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ']{1,}$/i.test(
        address
      )
    ) {
      error = 'adresse non valide';
    }
    return error;
  }

  /**
   * Validate names
   */
  async function validateName(name) {
    let error;
    if (isEmpty(name)) {
      error = 'Champ requis';
    } else if (!/^[a-zA-ZÀ-ÿ '-.,]{1,32}$/.test(name)) {
      error = 'non valide';
    }
    return error;
  }

  function validatePassword(password) {
    let error;
    setPassword(password);
    if (isEmpty(password)) {
      error = 'Champ requis';
    } else if (
      !/(((?=.*\W+)(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*\W+)(?=.*[a-z]))|((?=.*\d)(?=.*\W+)(?=.*[A-Z]))).*$/.test(
        password
      ) ||
      password.length < 8
    ) {
      error =
        'Votre mot de passe doit avoir au moins 8 caractères et respecter au moins 3 des conditions suivantes : une minuscule, une majuscule, un chiffre, un caractère spécial';
    }
    return error;
  }

  function validateConfirmPassword(confirm_password) {
    let error;
    if (password !== confirm_password) {
      error = 'Les 2 mots de passe ne sont identiques';
    }
    return error;
  }

  function validateRequired(value) {
    let error;
    if (isEmpty(value)) {
      error = 'Champ requis';
    }
    return error;
  }

  /**
   * Validate phone number
   * all these version:
   * 0601234567
   * +33601020304
   * 0033701020304
   */
  function validatePhoneNumber(phoneNumber) {
    // Must validate phone from france or luxembourg
    let error;
    if (isEmpty(phoneNumber)) {
      error = 'Champ requis';
    } else if (
      !/^(?:(?:\+33|0033|0)[1-9](?:[\s.-]*\d{2}){4})$/.test(phoneNumber) &&
      !/^(?:\+352|00352)6[269]1(?:\s?\d{3}){2}$/.test(phoneNumber) &&
      !/^(?:\+32|0032)[1-9]\d{1,3}(\s?\d{2,3}){2}$/.test(phoneNumber)
    ) {
      error =
        'Numero de téléphone non valide, Ex: 0601234567, ou +33601234567, ou +352621871922 pour le Luxembourg, ou +32901234567 pour la Belgique';
    }
    return error;
  }

  /**
   * Validate post code
   */
  function validatePostCode(post_code) {
    // Must validate post code from france or luxembourg or belgium
    let error;
    if (isEmpty(post_code)) {
      error = 'Champ requis';
    } else if (
      (isNaN(post_code) || post_code.length !== 5) &&
      !/((L)[\-])?(\d{4})/.test(post_code) &&
      !/^(?:(?:[1-9])(?:\d{3}))$/
    ) {
      error = 'Code postal non valide';
    }
    return error;
  }

  function validateConsent(value) {
    let error;
    if (!value) {
      error = 'Vous devez donner votre consentement pour vous abonner.';
    }
    return error;
  }

  const mapping = {
    email: validateEmail,
    password: validatePassword,
    confirm_password: validateConfirmPassword,
    first_name: validateName,
    last_name: validateName,
    phoneNumber: validatePhoneNumber,
    address: validateRequired,
    post_code: validatePostCode,
    city: validateName,
    country: validateRequired,
    billing_address: validateRequired,
    billing_post_code: validatePostCode,
    billing_city: validateRequired,
    billing_country: validateRequired,
  };

  React.useEffect(() => {
    const fn = ctaInViewport.bind(document.firstElementChild);
    fn();
    document.addEventListener('scroll', fn);
    return () => {
      document.removeEventListener('scroll', fn);
    };
  }, []);

  React.useEffect(() => {
    if (subscribedToMarketing) return;
    async function subscribeToAbandonedCartMarketing() {
      if (!fertilityTestResults) {
        return;
      }

      const endpoint = `${process.env.GATSBY_API}/hubspot/audience/payment/members`;
      setSubscribedToMarketing(true);
      await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          progress: 'page_paiement',
          email: fertilityTestResults.email,
        }),
      });
    }
    // Subscribe only if cart was seen for first time

    subscribeToAbandonedCartMarketing();
  }, [fertilityTestResults]);

  /**
   * Check if cta should be fixed when not visible
   */
  function ctaInViewport() {
    const threshold = 80;
    let scrollTop = this.scrollTop;
    let scrollRange = Math.abs(this.scrollHeight - this.clientHeight);
    let scrollBottom = Math.abs(scrollTop - scrollRange);

    if (scrollBottom > threshold) return setFixed(true);
    setFixed(false);
  }

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  const getUtm = () => {
    const utm = localStorage.getItem('utm');

    if (!utm) {
      return null;
    }

    try {
      const parsedUtm = JSON.parse(utm);

      // The server won't accept UTM values that are not strings
      // We can't fully control what are the UTM values, be we can cast
      // everything to string before talking with the server
      for (const key in parsedUtm) {
        if (typeof parsedUtm[key] !== 'string') {
          Sentry.captureMessage(`${key} error: ${localStorage.getItem('utm')}`);
          parsedUtm[key] = `${parsedUtm[key]}`;
        }
      }

      // The server won't accept a UTM object without a utm_source
      // If for some reason this value is missing, use a placeholder
      if (!parsedUtm.utm_source) {
        Sentry.captureMessage(`Invalid utm_source: ${localStorage.getItem('utm')}`);
        parsedUtm.utm_source = 'unknown';
      }

      return parsedUtm;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  };

  const createStripeCheckoutSession = async (values) => {
    const utm = getUtm();

    // Loading
    // setLoader(true);

    //setStoredCheckoutForm(values);

    /**
     * On Click on 'Continuer'
     * Send AddPaymentInfo to facebook
     */
    setFbEventName('AddPaymentInfo');
    axios
      .post(`${process.env.GATSBY_API}/stripe/checkout`, {
        domainURL: `https://${location.host}`,
        firstname: values.first_name,
        lastname: values.last_name,
        email: values.email,
        source: values.source,
        password: values.password,
        addressLine1: values.address,
        addressLine2: values.address2 ? values.address2 : '',
        zipCode: values.post_code,
        city: values.city,
        country: values.country,
        phoneNumber: values.phoneNumber,
        withEssentielCoaching: cart.contents.items.map((item) => item.id).includes(products.ESSENTIEL_COACHING),
        withEssentielSupplements: cart.contents.items.map((item) => item.id).includes(products.ESSENTIEL_SUPPLEMENTS),
        withShipping: !cart.freeShipping,
        withCoachingOption: cart.contents.items.map((item) => item.id).includes(products.COACHING),
        withStructurantOption: cart.contents.items.map((item) => item.id).includes(products.EQUILIBRE),
        withProtecteurOption: cart.contents.items.map((item) => item.id).includes(products.ANTIOX),
        membershipPeriod: cart.membershipPeriod,
        programType: cart.contents.program,
        withShipping: !cart.freeShipping,
        pickupId: values.pickupId,
        pickupAddress: values.pickupAddress,
        // Use promotion_code id cf issue fablife/bemum#1378
        promotionCodeId: cart.coupon && cart.coupon.id ? cart.coupon.id : null,
        utm: utm,
      })
      .then(({ data }) => {
        if (utm) localStorage.removeItem('utm');
        window.open(data.url, '_self').focus();
      })
      .catch((error) => {
        Sentry.captureException(error);
        setLoader(false);
      });
  };

  function handleAddress(selectedAddress) {
    setAddress(selectedAddress);
  }

  const shipping_option = (e, setFieldValue) => {
    const isChecked = e.target.checked;

    if (isChecked && !showModal) {
      setSetShowModal(true);
      setFieldValue('relay_point', true);
    } else if (!isChecked) {
      setFieldValue('relay_point', false);
      setSetShowModal(false);
      setShippingOption(false);
    }
  };

  const pointSelect = (data) => {
    setSelectPoint(data);
    setRelayPointId(data.identifiant);
    setShippingOption(true);
  };

  const closeModal = () => {
    setSetShowModal(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        if (!!address) {
          values.address = `${address.streetNumber || ''} ${address.route}`;
          values.post_code = address.postalCode;
          values.city = address.city;
          values.country = address.country;
          values.pickupId = shippingOption && relayPointId ? relayPointId : null;
          values.pickupAddress =
            shippingOption && selectPoint
              ? `${selectPoint.nom} , ${selectPoint.adresse1},${selectPoint.codePostal},${selectPoint.localite},${selectPoint.libellePays} `
              : null;
        }

        let finalSource = values.source;
        if (values.source === 'doctor' || values.source === 'other') {
          finalSource = values.textSource;
        }
        const dataToSend = {
          ...values,
          source: finalSource,
        };
        console.log(dataToSend);
        await createStripeCheckoutSession(dataToSend);
        // actions.resetForm({
        //   values: initialValues,
        //   errors: {},
        // });
      }}
      validateOnMount={true}
    >
      {({ values, errors, touched, isValid, dirty, setFieldValue, handleChange }) => (
        <Form>
          <div className={style.container}>
            <div>
              <div className={style.forms}>
                <ReactTooltip place="top" id="pay-button-tip" uuid="t1969a1dc-d357-49d5-b1de-f4238f19d95f" />

                <ReactTooltip place="top" id="password-tip" uuid="t1969a1dc-d357-49d5-b1de-f4238f19d95l" />
                <ReactTooltip place="top" id="pickup-button-tip" />
                <div className={`mb3`}>
                  {/* Client */}
                  <h2 className={`${style.t2}`}>{client.title}</h2>
                </div>

                <div className={`grid mb5`}>
                  {client.inputs.map((input, key) => (
                    <div key={key} className={`grid__item ${input.grid} mb2`}>
                      <div className={formStyle.InputWrapper}>
                        <label htmlFor={input.name} className={`${formStyle.Label}`}>
                          {input.label}
                        </label>
                        <Field
                          className={`${formStyle.Input} p0`}
                          name={input.name}
                          type={input.type}
                          placeholder={input.placeholder}
                          validate={mapping[input.name]}
                          data-for={input.name === 'password' ? 'password-tip' : ''}
                          data-tip={
                            input.name === 'password'
                              ? 'Votre mot de passe doit avoir au moins 8 caractères et respecter au moins 3 des conditions suivantes : une minuscule, une majuscule, un chiffre, un caractère spécial'
                              : ''
                          }
                        />
                        {errors[input.name] && touched[input.name] && (
                          <span className={`${formStyle.Error}`}>{errors[input.name]}</span>
                        )}
                      </div>
                    </div>
                  ))}
                  <div className={`grid__item ${source.grid} mb2`}>
                    <div className={formStyle.InputWrapper}>
                      <label htmlFor={source.name} className={`${formStyle.Label}`}>
                        {source.label}
                      </label>
                      <div className={formStyle.Select}>
                        <div className={formStyle.Select__Wrapper}>
                          <Field
                            name="source"
                            value={values.source}
                            type={source.type}
                            component="select"
                            validate={mapping[source.name]}
                            className={style.select}
                            onChange={(e) => {
                              const { value } = e.target;
                              setSelectedValue(value);
                              setFieldValue('source', value);
                            }}
                          >
                            {source.options.map((option, i) => (
                              <option key={i} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`grid__item ${source.grid} mb2`}>
                    {(selectedValue === 'doctor' || selectedValue === 'other') && (
                      <div className={formStyle.InputWrapper}>
                        <Field
                          name="textSource"
                          className={`${formStyle.Input} p0`}
                          placeholder={selectedValue === 'doctor' ? 'Nom de médecin' : 'Autre'}
                          value={values.textSource}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {/* Shipping */}
                <div className={`grid `}>
                  <h2 className={`${style.t2} mb3`}>{shipping.title}</h2>

                  {shipping.inputs.map((input, key) => {
                    return (
                      <div key={key} className={`grid__item ${input.grid} mb2`}>
                        <div className={formStyle.InputWrapper}>
                          <label htmlFor={input.name} className={`${formStyle.Label}`}>
                            {input.label}
                          </label>

                          {input.name === 'address' ? (
                            <AddressAuto
                              className={`${formStyle.Input}`}
                              name={input.name}
                              type={input.type}
                              placeholder={input.placeholder}
                              validate={mapping[input.name]}
                              onAddress={handleAddress}
                            />
                          ) : input.name === 'post_code' ? (
                            <Field
                              className={`${formStyle.Input}`}
                              name={input.name}
                              type={input.type}
                              placeholder={input.placeholder}
                              value={!!address ? address.postalCode : values.post_code}
                            />
                          ) : input.name === 'city' ? (
                            <Field
                              className={`${formStyle.Input}`}
                              name={input.name}
                              type={input.type}
                              placeholder={input.placeholder}
                              value={!!address ? address.city : values.city}
                            />
                          ) : (
                            <Field
                              className={`${formStyle.Input}`}
                              name={input.name}
                              type={input.type}
                              placeholder={input.placeholder}
                              validate={mapping[input.name]}
                            />
                          )}

                          {errors[input.name] && touched[input.name] && (
                            <span className={`${formStyle.Error}`}>{errors[input.name]}</span>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <div className={`grid__item ${country.grid} mb2`}>
                    <div className={formStyle.InputWrapper}>
                      <label htmlFor={country.name} className={`${formStyle.Label}`}>
                        {country.label}
                      </label>
                      <div className={formStyle.Select}>
                        <div className={formStyle.Select__Wrapper}>
                          <Field
                            name={country.name}
                            value={!!address ? address.country : values.country}
                            type={country.type}
                            component="select"
                            validate={mapping[country.name]}
                            className={style.select}
                          >
                            {country.options.map((option, i) => (
                              <option key={i} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Field>
                          {errors[country.name] && touched[country.name] && (
                            <span className={`${formStyle.Error}`}>{errors[country.name]}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={`grid__item ${same_billing.grid} mb2`}>
                    <div className={formStyle.InputWrapper}>
                      <Field
                        className={`p0 ${formStyle.Checkbox} `}
                        name={same_billing.name}
                        type={same_billing.type}
                      />
                      <div className={`${formStyle.InputWrapperLabel}`}>
                        <label htmlFor={same_billing.name} className={`${style.same_billing}`}>
                          {same_billing.label}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* relay point  */}
                <div className="mb5">
                  <div className={formStyle.InputWrapper}>
                    <Field
                      className={`p0 ${formStyle.Checkbox} `}
                      name={relay_point.name}
                      type={relay_point.type}
                      disabled={
                        address === null && (!values.address || !values.post_code || !values.city || !values.country)
                      }
                      onChange={(e) => {
                        shipping_option(e, setFieldValue);
                      }}
                      data-for={
                        !values.address || !values.post_code || !values.city || !values.country
                          ? 'pickup-button-tip'
                          : ''
                      }
                      data-tip={
                        !values.address
                          ? 'Veuillez remplir votre adresse avant de sélectionner une option de point relais'
                          : ''
                      }
                    />

                    <div className={`${formStyle.InputWrapperLabel}`}>
                      <label htmlFor={relay_point.name} className={`${style.same_billing}`}>
                        {relay_point.label}
                      </label>
                    </div>
                  </div>
                </div>

                <Modal
                  isOpen={showModal}
                  onRequestClose={closeModal}
                  style={{
                    overlay: {
                      backgroundColor: 'rgba(0, 0, 0, 0.58)',
                      zIndex: 9000,
                    },
                    content: {
                      top: typeof window !== 'undefined' && window.innerWidth <= 450 ? '10%' : '50%',
                      left: typeof window !== 'undefined' && window.innerWidth <= 450 ? '80%' : '57%',
                      right: 'auto',
                      bottom: 'auto',
                      transform: 'translate(-50%, -50%)',
                      overflow: 'visible',
                      padding: '0',
                      border: 'none',
                      backgroundColor: 'transparent',
                      width: typeof window !== 'undefined' && window.innerWidth <= 450 ? '145%' : '100%',
                    },
                  }}
                >
                  <ParcelShopWidget
                    onPointSelected={pointSelect}
                    onClose={closeModal}
                    onField={setFieldValue}
                    data={
                      address
                        ? {
                            adresse: `${address.streetNumber || ''} ${address.route}`,
                            code: address.postalCode,
                            city: address.city,
                            country: address.country,
                          }
                        : {
                            adresse: values.address,
                            code: values.post_code,
                            city: values.city,
                            country: values.country,
                          }
                    }
                  />
                </Modal>

                {selectPoint && shippingOption && (
                  <div className={`${style.relay}`}>
                    <p>{selectPoint.nom}</p>
                    <p>{selectPoint.adresse1}</p>
                    <p>
                      {selectPoint.codePostal} {selectPoint.localite} {selectPoint.libellePays}
                    </p>
                  </div>
                )}

                {/* Billing */}
                {!values.same_billing && (
                  <>
                    <h2 className={`${style.t2} mb3`}>{billing.title}</h2>
                    <div className={`grid mb5`}>
                      {billing.inputs.map((input, key) => {
                        return (
                          <div key={key} className={`grid__item ${input.grid} mb2`}>
                            <div className={formStyle.InputWrapper}>
                              <label htmlFor={input.name} className={`${formStyle.Label}`}>
                                {input.label}
                              </label>
                              <Field
                                className={`${formStyle.Input}`}
                                name={input.name}
                                type={input.type}
                                placeholder={input.placeholder}
                                validate={mapping[input.name]}
                              />
                              {errors[input.name] && touched[input.name] && (
                                <span className={`${formStyle.Error}`}>{errors[input.name]}</span>
                              )}
                            </div>
                          </div>
                        );
                      })}
                      <div className={`grid__item ${billing_country.grid} mb2`}>
                        <div className={formStyle.InputWrapper}>
                          <label htmlFor={billing_country.name} className={`${formStyle.Label}`}>
                            {billing_country.label}
                          </label>
                          <div className={formStyle.Select}>
                            <div className={formStyle.Select__Wrapper}>
                              <Field
                                name={billing_country.name}
                                type={billing_country.type}
                                component="select"
                                validate={mapping[billing_country.name]}
                              >
                                {billing_country.options.map((option, i) => (
                                  <option key={i} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* Consent */}
                <h3 className={`${style.consent_title} mb3`}>{consent.consent_title}</h3>
                <div className={`p1 Regular`} dangerouslySetInnerHTML={{ __html: consent.consent_subscriber }}></div>
                <div className={`${formStyle.InputWrapper} flex mb1 ${formStyle.Consent}`}>
                  <Field
                    className={`${formStyle.Checkbox} `}
                    name="consent_ppd"
                    type="checkbox"
                    validate={validateConsent}
                  />
                  <div className={`${formStyle.InputWrapperLabel}`}>
                    <label htmlFor="consent_ppd" className={`DeepBlack regular p1`}>
                      {consent.consent_ppd}
                    </label>
                  </div>
                  {errors['consent_ppd'] && touched['consent_cgv'] && (
                    <span className={`${formStyle.Error}`}>{errors['consent_ppd']}</span>
                  )}
                </div>

                {/* <div className={`${formStyle.InputWrapper} flex mb3 ${formStyle.Consent}`}>
                  <Field
                    className={`${formStyle.Checkbox} `}
                    name="consent_cgv"
                    type="checkbox"
                    validate={validateConsent}
                  />

                  <div className={`${formStyle.InputWrapperLabel}`}>
                    <label
                      htmlFor="consent_cgv"
                      className={`DeepBlack regular p1`}
                      dangerouslySetInnerHTML={{ __html: consent.consent_cgv }}
                    ></label>
                  </div>
                  {errors['consent_cgv'] && touched['consent_cgv'] && (
                    <span className={`${formStyle.Error}`}>{errors['consent_cgv']}</span>
                  )}
                </div> */}
              </div>
            </div>

            <div>
              <div className={`${style.Recap}`}>
                <div>
                  <div className={style.products}>
                    <h3 className={style.commande}>
                      Votre commande <span className={'lg-hide'}>{cart.total}€</span>
                    </h3>
                    {cart.contents.program === 'essentiel' && (
                      <CartItem
                        checked={cart.contents.items.find((item) => item.id === products.ESSENTIEL_SUPPLEMENTS)}
                        item={cart.definition.availablePrograms['essentiel']}
                        isForCheckoutView={true}
                      />
                    )}
                    <CartItem
                      checked={cart.contents.items.find((item) => item.id === products.ANTIOX)}
                      item={cart.definition.availableProducts['antiox']}
                      isForCheckoutView={true}
                    />
                    <CartItem
                      checked={cart.contents.items.find((item) => item.id === products.COACHING)}
                      item={cart.definition.availableProducts['coaching']}
                      isForCheckoutView={true}
                    />
                    <CartItem
                      checked={cart.contents.items.find((item) => item.id === products.EQUILIBRE)}
                      item={cart.definition.availableProducts['equilibre']}
                      isForCheckoutView={true}
                    />
                    <CartItem
                      checked={cart.contents.items.find((item) => item.id === products.SOPK_1_MONTH)}
                      item={cart.definition.availableProducts['sopk-1-month']}
                      isForCheckoutView={true}
                    />
                    <CartItem
                      checked={cart.contents.items.find((item) => item.id === products.SOPK_3_MONTHS)}
                      item={cart.definition.availableProducts['sopk-3-months']}
                      isForCheckoutView={true}
                    />
                    <CartItem
                      checked={cart.contents.items.find((item) => item.id === products.SOPK_6_MONTHS)}
                      item={cart.definition.availableProducts['sopk-6-months']}
                      isForCheckoutView={true}
                    />
                  </div>
                  <div className={style.total_wrapper}>
                    <div className={style.shipping_promo}>
                      <span>🎁 Livraison offerte jusqu’au 31 décembre ! Profitez-en vite ! 🎄✨</span>
                    </div>
                    <div className={style.total}>
                      <span>Sous-total :</span>
                      <span>{cart.subtotal}€</span>
                    </div>
                    {/* <div className={style.total}>
                      <span>Livraison&nbsp;:</span>
                      <span>{cart.freeShipping ? 'Offert' : shippingOption ? '5€' : '6.5€'}</span>
                    </div> */}
                    <div className={style.total}>
                      <span>Livraison&nbsp;:</span>
                      <span style={{ textDecoration: 'line-through' }}>{shippingOption ? '5€' : '6.5€'}</span>
                    </div>
                    {cart && (cart?.discountAmount || cart?.discountPercent) && (
                      <div className={style.totalLine}>
                        <span>Code promo&nbsp;:</span>
                        <span>
                          {cart.coupon.coupon.amount_off ? `-${cart.discountAmount}€` : `-${cart.discountPercent}%`}
                        </span>
                      </div>
                    )}
                    <div className={style.total}>
                      <span>Total :</span>
                      {/* <span>{cart.total >= 100 ? cart.total : shippingOption ? cart.total - 1.5 : cart.total}€</span> */}

                      <span>{cart.total}€</span>
                    </div>
                  </div>
                </div>
                <div className="Center">
                  <h3 className={style.payment}>Plateforme de paiement</h3>
                  <div className={`${formStyle.ButtonsContainer}`}>
                    <button
                      className={`${
                        !isValid || loading
                          ? `${formStyle.BlackBtn} ${formStyle.BtnDisable}  `
                          : `${formStyle.BlackBtn} `
                      }`}
                      type="submit"
                      disabled={loading || !isValid}
                      data-for={!(isValid && dirty) ? 'pay-button-tip' : ''}
                      data-tip={
                        !isValid
                          ? 'Votre formulaire contient des erreurs. Merci de vérifier vos informations avant de soumettre à nouveau.'
                          : ''
                      }
                    >
                      Paiement
                    </button>

                    <div className={`${formStyle.InputWrapper} flex mt3 ${formStyle.Consent}`}>
                      <div className={`${formStyle.InputWrapperLabel}`}>
                        <label
                          htmlFor="consent_cgv"
                          className={`DeepBlack regular p1`}
                          dangerouslySetInnerHTML={{ __html: consent.consent_cgv }}
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default function (props) {
  return (
    <StaticQuery
      render={(data) => <Checkout data={data} {...props} />}
      query={graphql`
        {
          pageCheckoutYaml {
            button
            client {
              title
              info
              inputs {
                label
                name
                placeholder
                type
                grid
              }
            }
            source {
              name
              label
              placeholder
              type
              grid
              options {
                value
                label
              }
            }
            shipping {
              title
              inputs {
                label
                name
                placeholder
                type
                grid
              }
            }
            country {
              name
              label
              placeholder
              type
              grid
              options {
                value
                label
              }
            }
            same_billing {
              name
              label
              type
              grid
            }
            relay_point {
              name
              label
              type
              grid
            }
            billing {
              title
              inputs {
                label
                name
                placeholder
                type
                grid
              }
            }
            billing_country {
              name
              label
              placeholder
              type
              grid
              options {
                value
                label
              }
            }
            consent {
              consent_title
              consent_subscriber
              consent_ppd
              consent_cgv
            }
          }
        }
      `}
    />
  );
}
