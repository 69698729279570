import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import { keyframes, styled } from '@stitches/react';
import { graphql, useStaticQuery } from 'gatsby';
import Link from '../utils/link';
import React from 'react';

export default function Menu() {
  const data = useStaticQuery(graphql`
    query {
      allStaticNavbarYaml {
        nodes {
          caret {
            desktop {
              img {
                publicURL
              }
              img_red {
                publicURL
              }
              alt
            }
          }
          navbar {
            color
            link
            text
            img {
              publicURL
            }
            alt
            items {
              color
              link
              text
              img {
                publicURL
              }
              extern
              alt
            }
          }
        }
      }
    }
  `);

  const enterFromRight = keyframes({
    from: { transform: 'translateX(200px)', opacity: 0 },
    to: { transform: 'translateX(0)', opacity: 1 },
  });

  const enterFromLeft = keyframes({
    from: { transform: 'translateX(-200px)', opacity: 0 },
    to: { transform: 'translateX(0)', opacity: 1 },
  });

  const exitToRight = keyframes({
    from: { transform: 'translateX(0)', opacity: 1 },
    to: { transform: 'translateX(200px)', opacity: 0 },
  });

  const exitToLeft = keyframes({
    from: { transform: 'translateX(0)', opacity: 1 },
    to: { transform: 'translateX(-200px)', opacity: 0 },
  });

  const scaleIn = keyframes({
    from: { transform: 'rotateX(-30deg) scale(0.9)', opacity: 0 },
    to: { transform: 'rotateX(0deg) scale(1)', opacity: 1 },
  });

  const scaleOut = keyframes({
    from: { transform: 'rotateX(0deg) scale(1)', opacity: 1 },
    to: { transform: 'rotateX(-10deg) scale(0.95)', opacity: 0 },
  });

  const fadeIn = keyframes({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  const fadeOut = keyframes({
    from: { opacity: 1 },
    to: { opacity: 0 },
  });

  const StyledMenu = styled(NavigationMenuPrimitive.Root, {
    position: 'relative',
    justifyContent: 'center',
    zIndex: 1,
    display: 'none',
    '@media only screen and (min-width: 1024px)': {
      display: 'flex',
    },
  });

  const StyledList = styled(NavigationMenuPrimitive.List, {
    all: 'unset',
    display: 'flex',
    justifyContent: 'center',
    listStyle: 'none',
  });

  const itemStyles = {
    padding: '10px 17.5px',
    outline: 'none',
    userSelect: 'none',
    lineHeight: 1,
    fontSize: 15,
    textUnderlineOffset: '0.7rem',
    '&:hover': { textDecoration: 'underline' },
  };

  const StyledTrigger = styled(NavigationMenuPrimitive.Trigger, {
    all: 'unset',
    ...itemStyles,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 2,
    textTransform: 'uppercase',
  });

  const StyledCaret = styled('img', {
    position: 'relative',
    marginLeft: '7px',
    transform: 'rotate(180deg)',
    color: 'rgb(100, 79, 193)',
    top: 1,
    '[data-state=open] &': { transform: 'rotate(0deg)' },
    '@media (prefers-reduced-motion: no-preference)': {
      transition: 'transform 250ms ease',
    },
  });

  const StyledTriggerWithCaret = React.forwardRef(({ children, ...props }, forwardedRef) => (
    <StyledTrigger {...props} ref={forwardedRef}>
      {children}
      {children === 'Acheter le programme' ? (
        <StyledCaret
          aria-hidden
          src={data.allStaticNavbarYaml.nodes[0].caret.desktop.img.publicURL}
          alt={data.allStaticNavbarYaml.nodes[0].caret.desktop.img.alt}
        />
      ) : (
        <StyledCaret
          aria-hidden
          src={data.allStaticNavbarYaml.nodes[0].caret.desktop.img_red.publicURL}
          alt={data.allStaticNavbarYaml.nodes[0].caret.desktop.img_red.alt}
        />
      )}
    </StyledTrigger>
  ));

  const StyledLink = styled(NavigationMenuPrimitive.Link, {
    ...itemStyles,
    display: 'block',
    textDecoration: 'none',
    fontSize: 15,
    lineHeight: 1,
  });

  const RouterLink = ({ children, to, ...props }) => {
    return (
      <StyledLink asChild>
        <Link to={to} {...props}>
          {children}
        </Link>
      </StyledLink>
    );
  };

  const StyledContent = styled(NavigationMenuPrimitive.Content, {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    '@media only screen and (min-width: 600px)': { width: 'auto' },
    '@media (prefers-reduced-motion: no-preference)': {
      animationDuration: '250ms',
      animationTimingFunction: 'ease',
      '&[data-motion="from-start"]': { animationName: enterFromLeft },
      '&[data-motion="from-end"]': { animationName: enterFromRight },
      '&[data-motion="to-start"]': { animationName: exitToLeft },
      '&[data-motion="to-end"]': { animationName: exitToRight },
    },
  });

  const StyledIndicator = styled(NavigationMenuPrimitive.Indicator, {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: 10,
    top: '100%',
    overflow: 'hidden',
    zIndex: 1,

    '@media (prefers-reduced-motion: no-preference)': {
      transition: 'width, transform 250ms ease',
      '&[data-state="visible"]': { animation: `${fadeIn} 200ms ease` },
      '&[data-state="hidden"]': { animation: `${fadeOut} 200ms ease` },
    },
  });

  const StyledArrow = styled('div', {
    position: 'relative',
    top: '70%',
    backgroundColor: 'white',
    width: 10,
    height: 10,
    transform: 'rotate(45deg)',
    borderTopLeftRadius: 2,
  });

  const StyledIndicatorWithArrow = React.forwardRef((props, forwardedRef) => (
    <StyledIndicator {...props} ref={forwardedRef}>
      <StyledArrow />
    </StyledIndicator>
  ));

  const StyledViewport = styled(NavigationMenuPrimitive.Viewport, {
    position: 'relative',
    transformOrigin: 'top center',
    marginTop: 10,
    width: '100%',
    backgroundColor: 'white',
    overflow: 'hidden',
    boxShadow: '0px 10px 25px #0000000D;',
    height: 'var(--radix-navigation-menu-viewport-height)',

    '@media only screen and (min-width: 600px)': {
      width: 'var(--radix-navigation-menu-viewport-width)',
    },
    '@media (prefers-reduced-motion: no-preference)': {
      transition: 'width, height, 300ms ease',
      '&[data-state="open"]': { animation: `${scaleIn} 200ms ease` },
      '&[data-state="closed"]': { animation: `${scaleOut} 200ms ease` },
    },
  });

  // Exports
  const NavigationMenu = StyledMenu;
  const NavigationMenuList = StyledList;
  const NavigationMenuItem = NavigationMenuPrimitive.Item;
  const NavigationMenuTrigger = StyledTriggerWithCaret;
  const NavigationMenuLink = RouterLink;
  const NavigationMenuContent = StyledContent;
  const NavigationMenuViewport = StyledViewport;
  const NavigationMenuIndicator = StyledIndicatorWithArrow;

  const ContentList = styled('ul', {
    padding: '15px 10px',
    margin: 0,
    listStyle: 'none',
    width: 350,
    boxShadow: '0px 10px 25px #0000000D',
  });

  const LinkText = styled('div', {
    all: 'unset',
    color: 'rgb(111, 110, 119)',
    lineHeight: 1.4,
    fontWeight: 'initial',
  });

  const ContentListItem = React.forwardRef(({ children, title, ...props }, forwardedRef) => (
    <li>
      <NavigationMenuLink
        {...props}
        ref={forwardedRef}
        css={{
          padding: 12,
          borderRadius: 6,
          '&:hover': { backgroundColor: 'rgb(244, 242, 244)' },
        }}
      >
        <LinkText>{children}</LinkText>
      </NavigationMenuLink>
    </li>
  ));

  const ViewportPosition = styled('div', {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    top: '100%',
    left: 250,
    perspective: '2000px',
  });

  return (
    <NavigationMenu>
      <NavigationMenuList>
        {data.allStaticNavbarYaml.nodes[0].navbar.map((nav, index) => (
          <React.Fragment key={index + nav.link}>
            {nav.link && (
              <NavigationMenuItem>
                <NavigationMenuLink
                  asChild
                  style={{
                    color: nav.color,
                    textUnderlineOffset: '0.8rem',
                    letterSpacing: index === 4 ? 0 : '0.64px',
                    fontSize: index === 5 ? '14px' : '16px',
                    textTransform: index === 0 ? 'uppercase' : null,
                    fontWeight: index === 0 ? 500 : null,
                    borderLeft: index === 5 ? '1px solid rgba(112, 112, 112, 0.26)' : null,
                  }}
                >
                  {!nav.link.startsWith('http') ? (
                    <Link activeStyle={{ textDecoration: 'underline', fontWeight: 500 }} to={nav.link}>
                      <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                        {nav.img && <img src={nav.img.publicURL} alt={nav.alt} style={{ marginRight: '10px' }} />}

                        {nav.text} 
                      </div>
                    </Link>
                  ) : (
                    <a href={nav.link} target="_blank" rel="noreferrer">
                      <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                        {nav.img && <img src={nav.img.publicURL} alt={nav.alt} style={{ marginRight: '10px' }} />}
                        {nav.text}
                      </div> 
                    </a>
                  )}
                </NavigationMenuLink>
              </NavigationMenuItem>
            )}

            {nav.items && (
              <NavigationMenuItem>
                <NavigationMenuTrigger
                  style={{
                    color: nav.color,
                    fontWeight: 500,
                    textUnderlineOffset: '0.8rem',
                    letterSpacing: '0.64px',
                    fontSize: '16px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {nav.text}
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ContentList>
                    {nav.items.map((item, index1) => (
                      <ContentListItem to={item.link} key={index + index1 + item.link} extern={item.extern}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {item.img && (
                            <img
                              src={item.img.publicURL}
                              alt={item.alt}
                              style={{ marginRight: '17px', width: '16px', height: '25px' }}
                            />
                          )}

                          <span style={{ whiteSpace: 'nowrap' }}> {item.text} </span>
                        </div>
                      </ContentListItem>
                    ))}
                  </ContentList>
                </NavigationMenuContent>
              </NavigationMenuItem>
            )}
          </React.Fragment>
        ))}

        <NavigationMenuIndicator />
      </NavigationMenuList>

      <ViewportPosition>
        <NavigationMenuViewport />
      </ViewportPosition>
    </NavigationMenu>
  );
}
