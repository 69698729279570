import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import slideLeftTransition from '../../../modules/transitions/slide-left.module.scss';
import slideRightTransition from '../../../modules/transitions/slide-right.module.scss';
import questionDefinitionProptypes from '../question-definition.proptypes';
import Checkbox from './checkbox';
import Hello from './hello';
import Input from './input';
import Interlude from './interlude';
import Radio from './radio';
import Range from './range';
import Welcome from './welcome';

/**
 * Extra advice from the BeMum expert
 * @typedef Expertise
 * @type {object}
 * @property {string} content - expert advice body
 * @property {string} title - expert advice title
 */

/**
 * Input question definition
 * @typedef Input
 * @type {object}
 * @property {string} placeholder - placeholder
 * @property {string} unit - eg. age, cm, kg...
 * @property {bool} decimal - should the input value have decimals ?
 * @property {number} min - minimum value
 * @property {number} max - maximum value
 * @property {number} step - interval between legal numbers
 * @property {number} default - default value
 */

/**
 * Checkbox / Radio question definition
 * @typedef Option
 * @type {object}
 * @property {string} label - Option label (eg "Choice #1")
 * @property {'radio'|'checkbox'} type - Option type (deprecated)
 * @property {string} value - Option value (eg "choice-1")
 * @property {string} detail - Extra info to display when this option is checked
 */

/**
 * Range question definition.
 *
 * Check out {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Input/range|MDN}
 * @typedef Range
 * @type {object}
 * @property {string[]} labels - Ranges labels (eg first and last values)
 * @property {number} max - Maximum value
 * @property {number} min - Minimum value
 * @property {number} step - Range step
 *
 */

/**
 * Question definition.
 * @typedef Definition
 * @type {object}
 * @property {number} consent - text to display if we need the user to opt in to something
 * @property {number} error_message - message to display when the question value does not pass validation
 * @property {string[]} exclude_opts - list of values which, when in use, disable the other values (useful for checkboxes)
 * @property {Expertise} expertise - Extra advice from the BeMum expert
 * @property {string} helper - Extra content to help the user understand the question
 * @property {Object} image - illustration for the question (Gatsby/Sharp)
 * @property {Input} input - Question input definition
 * @property {string} max_opt - maximum number of options the user can choose
 * @property {string} min_opt - minimum number of options the user can choose
 * @property {string} name - unique name of the question
 * @property {string} next - label for the "Next" button
 * @property {Option[]} options - Question options (for checkbox and radio types)
 * @property {Range} range - Question range definition (for range type)
 * @property {string} title - Question title
 * @property {'radio'|'checkbox'|'interlude'|'text'|'email'|'number'|'range'|'welcome'|'hello'} type - Question type
 */

/**
 * Generic fertility test question
 * @param {Object} props
 * @param {function} props.change - event handler to call when the question value changes
 * @param {Definition} props.definition - question definition
 * @param {*} props.value - question value if any
 * @param {string} props.firstname - user firstname
 * @param {'left'|'right'} props.motion - animation direction
 */
function Question({ definition, change, value, firstname, motion, answers, imc }) {
  const getQuestion = (definition) => {
    switch (definition.type) {
      case 'checkbox':
        return <Checkbox definition={definition} change={change} value={value} answers={answers} imc={imc} />;
      case 'email':
        return <Input definition={definition} change={change} value={value} />;
      case 'interlude':
        return <Interlude definition={definition} />;
      case 'number':
        return <Input definition={definition} change={change} value={value} />;
      case 'radio':
        return <Radio definition={definition} change={change} value={value} />;
      case 'range':
        return <Range definition={definition} change={change} value={value} />;
      case 'text':
        return <Input definition={definition} change={change} value={value} />;
      case 'textarea':
        return <Input definition={definition} change={change} value={value} />;
      case 'hello':
        return <Hello definition={definition} />;
      case 'welcome':
        return <Welcome definition={definition} firstname={firstname} />;
      default:
        throw new Error(`Unknown question type: ${definition.type}`);
    }
  };

  return (
    <SwitchTransition>
      <CSSTransition
        key={definition.name}
        // TODO : there's a bug here. To reproduce, observe the motion when doing
        // next next previous --> last animation is in the wrong direction
        // previous previous next --> last animation is in the wrong direction
        classNames={motion === 'left' ? slideLeftTransition : slideRightTransition}
        addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
      >
        {getQuestion(definition)}
      </CSSTransition>
    </SwitchTransition>
  );
}

Question.propTypes = {
  change: PropTypes.func.isRequired,
  definition: questionDefinitionProptypes.isRequired,
  value: PropTypes.any,
  firstname: PropTypes.string,
};

export default Question;
