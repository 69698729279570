import React from 'react';
import { useCart } from '../hooks/useCart';

/** BeMum products */
export const products = Object.freeze({
  ESSENTIEL_COACHING: 'essentielCoaching',
  ESSENTIEL_SUPPLEMENTS: 'essentielSupplements',
  ESSENTIEL_MENUS: 'essentielMenus',
  ANTIOX: 'antiox',
  EQUILIBRE: 'equilibre',
  COACHING: 'coaching',
});

/** BeMum programs */
export const programs = Object.freeze({
  ESSENTIEL: 'essentiel',
  AGE: 'age',
  SOPK: 'sopk',
});

export const MembershipPeriod = {
  MONTHLY: 'monthly',
  THREE_MONTHS: 'three_months',
  SIX_MONTHS: 'six_months',
};

/**
 * Version of the cart.
 * Bump this number when publishing a backward incompatible change to the Cart data structure.
 * useCart hook will destroy any cart using a previous version than this.
 *
 * @typedef CartVersion
 * @type {number}
 **/
export const CURRENT_CART_VERSION = 18;

/**
 * Data structure of a BeMum shopping cart
 * @typedef Cart
 * @type {object}
 * @property {CartContents} contents
 * @property {?StripeCoupon} coupon
 * @property {Definition} definition
 * @property {?number} discountAmount - Amount discounted from the cart subtotal.
 * @property {?number} discountPercent - Percent discounted from the cart subtotal.
 * @property {number} subtotal - Sum of each cart items price
 * @property {boolean} freeShipping - Shipping fees or not
 * @property {number} total - Sum of each cart items price, minus a possible discount
 * @property {CartVersion} version
 */

/** Contents of the cart, ie. it's what has been added by the customer.
 * @typedef CartContents
 * @property {Product[]} items - Products that will actually be bought
 * @property {?ProgramName} program - Name of the latest program added to the cart
 */

/**
 * Static, exhaustive definition of what BeMum sells. It is NOT supposed to be mutated at runtime.
 * @typedef Definition
 * @type {object}
 * @property {Object.<string, Product>} availableProducts - Products to be sold
 * @property {Object.<string, Program>} availablePrograms - Programs to be sold
 * @property {number} shippingFees - Price of the shipping
 */

/**
 * Data structure representing a BeMum product
 * @typedef Product
 * @type {object}
 * @property {string} description - Description of the product
 * @property {string} longDescription - Long description of the product
 * @property {?boolean} isLongDescriptionHideable - Should the long description be always shown, or should it be hideable
 * @property {?number} discountedPrice - Price of the product after applying a discount
 * @property {ProductName} id - Identifier of the product
 * @property {string} label - Label (name) of the product
 * @property {number} price - Initial price of the product, without any discount applied on it
 * @property {?string} refund - User-facing info about health insurance refunds
 * @property {string[]} stripe_product_id - The product identifier in Stripe
 */

/**
 * Data structure representing a BeMum program,
 * which is nothing more than a pack of BeMum products
 * @typedef Program
 * @type {object}
 * @property label - Label (Name) of the program
 * @property {ProductName[]} - List of products included in the program
 */

/**
 * Possible names for a BeMum program
 * @typedef ProgramName
 * @type {'essentiel'}
 */

/**
 * Possible names for a BeMum product
 * @typedef ProductName
 * @type {'essentielCoaching'|'essentielSupplements'|'essentielMenus'|'antiox'|'equilibre'|'coaching'}
 */

/**
 * Object definition here {@link https://stripe.com/docs/api/promotion_codes/object Stripe docs}
 * @typedef StripeCoupon
 * @type {object}
 */

/** @type {Cart} */
export const defaultCart = {
  contents: {
    items: [],
    program: null,
  },
  selectItems: [],
  coupon: null,
  definition: {
    availableProducts: {
      [products.ESSENTIEL_SUPPLEMENTS]: {
        description: 'Boosters de fertilité',
        longDescription:
          'Compléments alimentaires associant <span style="white-space: nowrap">oméga-3</span>, vitamines et minéraux (qualité EPAX©)',
        isLongDescriptionHideable: true,
        discountedPrice: null,
        price: {
          monthly: 31.5,
          three_months: 80,
          six_months: 151,
        },
        id: products.ESSENTIEL_SUPPLEMENTS,
        label: 'Les compléments Essentiels',
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_ESSENTIAL_SUPPLEMENTS,
      },
      [products.ESSENTIEL_COACHING]: {
        description: 'Nutritionniste spécialisée en fertilité',
        longDescription: 'Remboursable par votre mutuelle',
        isLongDescriptionHideable: false,
        discountedPrice: null,
        price: {
          monthly: 30.0,
          three_months: 76.5,
          six_months: 144,
        },
        id: products.ESSENTIEL_COACHING,
        label: '1 téléconsultation',
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_ESSENTIAL_COACHING,
      },
      [products.ANTIOX]: {
        description: 'Protection contre le stress oxydatif',
        discountedPrice: null,
        id: products.ANTIOX,
        label: 'Spécificité + 35 ans',
        price: {
          monthly: 19.8,
          three_months: 50.5,
          six_months: 95,
        },
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_35YO,
      },
      [products.EQUILIBRE]: {
        description: 'Gestion du poids et du SOPK',
        discountedPrice: null,
        id: products.EQUILIBRE,
        label: 'Spécificité poids / SOPK',
        price: {
          monthly: 16.5,
          three_months: 42.5,
          six_months: 80,
        },
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_WEIGHT,
      },
      [products.COACHING]: {
        description: "2 rdv / mois au lieu d'un seul",
        longDescription: 'Remboursable par votre mutuelle',
        isLongDescriptionHideable: false,
        discountedPrice: null,
        id: products.COACHING,
        label: 'Coaching +',
        price: {
          monthly: 30,
          three_months: 90,
          six_months: 180,
        },
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_COACHING,
      },
    },
    availablePrograms: {
      [programs.ESSENTIEL]: {
        id: programs.ESSENTIEL,
        label: 'Les Essentiels',
        products: [products.ESSENTIEL_SUPPLEMENTS, products.ESSENTIEL_COACHING],
      },
      [programs.AGE]: {
        id: programs.AGE,
        label: 'Age',
        products: [products.ESSENTIEL_SUPPLEMENTS, products.ESSENTIEL_COACHING, products.ANTIOX],
      },
      [programs.SOPK]: {
        id: programs.SOPK,
        label: 'Sopk',
        products: [products.ESSENTIEL_SUPPLEMENTS, products.ESSENTIEL_COACHING, products.EQUILIBRE],
      },
    },
    shippingFees: 6.5,
  },
  discountAmount: null,
  discountPercent: null,
  subtotal: 0,
  freeShipping: false,
  total: 0,
  membershipPeriod: MembershipPeriod.MONTHLY,
  version: CURRENT_CART_VERSION,
};

const CartContext = React.createContext({ cart: defaultCart, setCart: () => {} });

/** Provide a single source of truth for BeMum shopping cart contents. */
function CartProvider({ children }) {
  const [cart, setCart] = useCart();

  return <CartContext.Provider value={{ cart, setCart }}>{children}</CartContext.Provider>;
}

export default CartContext;
export { CartProvider };
