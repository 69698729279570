/** BeMum tag names in result page */
export const TagNames = Object.freeze({
  Weight: 'weight',
  Stress: 'stress',
  Sleep: 'sleep',
  PhysicalActivity: 'physical_activity',
  RelationshipToFood: 'relationship_to_food',
  Smoker: 'smoker',
  SmokingPartner: 'smoking_partner',
  Sopk: 'sopk',
  Sugar: 'sugar',
  Age: 'age',
  Environment: 'environment',
  Alcohol: 'alcohol',
  Antiox: 'antiox',
  Endometriose: 'endometriose',
  Omega3: 'omega_3',
});

export function getDescriptionForOptionItem(slug, answers) {
  if (!answers || !slug) return '';

  const infertility_diagnosis = answers['infertility_diagnosis']; // multiple answers possible
  const to_get_pregnant = answers['to_get_pregnant']; // multiple answers possible
  const weight = Number(answers['weight']);
  const height = Number(answers['height']);
  const smoker = answers['smoker'];
  const stress = parseInt(answers['stress'], 10);
  const sleep = parseInt(answers['sleep'], 10);
  const physical_activity = answers['physical_activity'];
  const relationship_to_food = answers['relationship_to_food']; // multiple answers possible
  const imc = weight / ((height / 100) * (height / 100));

  if (slug === 'antiox') return getDescriptionForAntioxOption();
  if (slug === 'equilibre')
    return getDescriptionForEquilibreOption(imc, to_get_pregnant, relationship_to_food, infertility_diagnosis);
  if (slug === 'coaching')
    return getDescriptionForCoachingOption(smoker, physical_activity, relationship_to_food, stress, sleep);
}

function getDescriptionForAntioxOption() {
  return `<p>Le <span class="Red"> complément alimentaire P+ </span> vous aidera à compenser les effets du <span class="Red"> stress oxydatif </span> lié à l’âge ou aux habitudes de vie. </p>`;
}

function getDescriptionForEquilibreOption(imc, to_get_pregnant, relationship_to_food, infertility_diagnosis) {
  let text = '';
  const content = [];

  text = `<p>Le <span class="Red"> complément alimentaire S+ </span> est conçu pour `;
  if (imc >= 27 || to_get_pregnant.includes('weight'))
    content.push(`faciliter la <span class="Red"> perte de poids</span>`);
  if (relationship_to_food.includes('food_4')) content.push(`limiter les fringales`);
  if (infertility_diagnosis.includes('sopk')) content.push(`réguler les cycles et favoriser l'ovulation spontanée`);

  text += content.length ? content.slice(0, -1).join(', ') + ' et ' + content.slice(-1) : '';
  text += `.</p>`;

  return text;
}

function getDescriptionForCoachingOption(smoker, physical_activity, relationship_to_food, stress, sleep) {
  let text = '';
  const content = [];

  text = `<p>Une 2ème session de coaching mensuelle nous semble indiquée pour `;
  if (smoker === 'oui') content.push(`vous aider à <span class="Red"> réduire la cigarette</span>`);
  if (physical_activity === 'rarely')
    content.push(`démarrer une <span class="Red"> activité physique modérée </span> mais régulière`);
  if (!relationship_to_food.includes('aucun'))
    content.push(`mieux appréhender votre <span class="Red">rapport à l'alimentation</span>`);
  if (stress >= 5) content.push(`diminuer votre <span class="Red"> niveau de stress</span>`);
  if (sleep >= 5) content.push(`<span class="Red">mieux dormir</span>`);

  text += content.length ? content.slice(0, -1).join(', ') + ' et ' + content.slice(-1) : '';
  text += `.</p>`;

  return text;
}

export function getTagsListForOptionItem(slug, answers) {
  if (!answers || !slug) return [];

  const infertility_diagnosis = answers['infertility_diagnosis']; // multiple answers possible
  const to_get_pregnant = answers['to_get_pregnant']; // multiple answers possible
  const age = parseInt(answers['age'], 10);
  const weight = Number(answers['weight']);
  const height = Number(answers['height']);
  const environment = answers['environment'];
  const smoker = answers['smoker'];
  const smoking_partner = answers['smoking_partner'];
  const alcohol = answers['alcohol'];
  const stress = parseInt(answers['stress'], 10);
  const sleep = parseInt(answers['sleep'], 10);
  const physical_activity = answers['physical_activity'];
  const fruits = answers['fruits'];
  const veggies = answers['veggies'];
  const relationship_to_food = answers['relationship_to_food'];
  const imc = weight / ((height / 100) * (height / 100));

  if (slug === 'antiox')
    return getTagsListForAntioxOption(age, environment, smoker, smoking_partner, alcohol, fruits, veggies);
  if (slug === 'poids')
    return getTagsListForEquilibreOption(imc, to_get_pregnant, relationship_to_food, infertility_diagnosis);
  if (slug === 'coaching')
    return getTagsListForCoachingOption(
      imc,
      to_get_pregnant,
      smoker,
      physical_activity,
      relationship_to_food,
      stress,
      sleep
    );
}

function getTagsListForAntioxOption(age, environment, smoker, smoking_partner, alcohol, fruits, veggies) {
  const content = [];

  if (age >= 35) content.push(TagNames.Age);
  if (environment === 'oui') content.push(TagNames.Environment);
  if (smoker === 'oui') content.push(TagNames.Smoker);
  if (smoking_partner === 'oui') content.push(TagNames.SmokingPartner);
  if (alcohol === '5+') content.push(TagNames.Alcohol);
  if (fruits === 'aucun' || veggies === 'aucun') content.push(TagNames.Antiox);

  return content;
}

function getTagsListForEquilibreOption(imc, to_get_pregnant, relationship_to_food, infertility_diagnosis) {
  const content = [];

  if (imc >= 27 || to_get_pregnant.includes('weight')) content.push(TagNames.Weight);
  if (relationship_to_food.includes('food_4')) content.push(TagNames.Sugar);
  if (infertility_diagnosis.includes('sopk')) content.push(TagNames.Sopk);

  return content;
}

function getTagsListForCoachingOption(
  imc,
  to_get_pregnant,
  smoker,
  physical_activity,
  relationship_to_food,
  stress,
  sleep
) {
  const content = [];

  if (smoker === 'oui') content.push(TagNames.Smoker);
  if (physical_activity === 'rarely') content.push(TagNames.PhysicalActivity);
  if (!relationship_to_food.includes('aucun')) content.push(TagNames.RelationshipToFood);
  if (stress >= 5) content.push(TagNames.Stress);
  if (sleep >= 5) content.push(TagNames.Sleep);
  if (imc >= 27 || to_get_pregnant.includes('weight')) content.push(TagNames.Weight);

  return content;
}

export function getTagsListForFactors(answers) {
  if (!answers) return [];

  const baby_journey = answers['baby_journey'];
  const infertility_diagnosis = answers['infertility_diagnosis']; // multiple answers possible
  const to_get_pregnant = answers['to_get_pregnant']; // multiple answers possible
  const age = parseInt(answers['age'], 10);
  const weight = Number(answers['weight']);
  const height = Number(answers['height']);
  const environment = answers['environment'];
  const smoker = answers['smoker'];
  const former_smoker = answers['former_smoker'];
  const smoking_partner = answers['smoking_partner'];
  const alcohol = answers['alcohol'];
  const endocrine_disruptor_sensitivity = answers['endocrine_disruptor_sensitivity'];
  const stress = parseInt(answers['stress'], 10);
  const sleep = parseInt(answers['sleep'], 10);
  const physical_activity = answers['physical_activity'];
  const fruits = answers['fruits'];
  const veggies = answers['veggies'];
  const oily_fish = answers['oily_fish'];
  const oil = answers['oil'];
  const relationship_to_food = answers['relationship_to_food'];
  const imc = weight / ((height / 100) * (height / 100));

  const content = [];

  if (imc >= 27 || to_get_pregnant.includes('weight')) content.push(TagNames.Weight);
  if (stress >= 5) content.push(TagNames.Stress);
  if (sleep >= 5) content.push(TagNames.Sleep);
  if (physical_activity === 'rarely') content.push(TagNames.PhysicalActivity);
  if (!relationship_to_food.includes('aucun')) content.push(TagNames.RelationshipToFood);
  if (smoker === 'oui') content.push(TagNames.Smoker);
  if (smoking_partner === 'oui') content.push(TagNames.SmokingPartner);
  if (infertility_diagnosis.includes('sopk')) content.push(TagNames.Sopk);
  if (relationship_to_food.includes('food_4')) content.push(TagNames.Sugar);
  if (age >= 35) content.push(TagNames.Age);
  if (environment === 'oui') content.push(TagNames.Environment);
  if (alcohol === '5+') content.push(TagNames.Alcohol);
  if (fruits === 'aucun' || veggies === 'aucun') content.push(TagNames.Antiox);
  if (infertility_diagnosis.includes('endometriose')) content.push(TagNames.Endometriose);
  if (oily_fish === 'aucun') content.push(TagNames.Omega3);

  return content;
}

export function getProfileDescription(answers) {
  if (!answers) return { content1: null, content2: null };

  const baby_journey = answers['baby_journey'];
  const to_get_pregnant = answers['to_get_pregnant']; // multiple answers possible

  let content1 = '';
  let content2 = '';

  if (to_get_pregnant.includes('weight')) {
    switch (baby_journey) {
      case 'je-veux-préparer-ma-grossesse':
        content1 = `<p>Vous souhaitez <span class="Bold">mettre toutes les chances de votre côté pour tomber enceinte</span> et pour démarrer votre grossesse dans les meilleures conditions possibles.</p>`;
        content2 = `<p><span class="Bold">Très bonne idée !</span> Pour cela <span class="Bold"> nos experts vous recommandent le programme suivant: </span></p>`;
        break;
      case 'je-n-arrive-pas-a-tomber-enceinte':
        content1 = `<p>Vous essayez de <span class="Bold">tomber enceinte</span> depuis quelques mois et cela commence à vous paraître long.</p>`;
        content2 = `<p>C'est effectivement <span class="Bold">le bon moment pour accélérer votre démarche !</span> Pour cela <span class="Bold">nos experts vous recommandent le programme suivant:</span></p>`;
        break;
      case 'j-ai-un-diagnostic-d-infertilite':
        content1 = `<p>Vous rencontrez des <span class="Bold">difficultés pour tomber enceinte</span> et êtes suivie médicalement pour cela.</p>`;
        content2 = `<p>Pour mieux compléter votre <span class="Bold"> suivi médical</span>, rien de mieux qu’une amélioration de vos habitudes alimentaires! Pour cela <span class="Bold">nos experts vous recommandent le programme suivant: </span> </p>`;
        break;
      default:
        throw new Error(`Unexpected value: ${baby_journey} `);
    }
  } else {
    switch (baby_journey) {
      case 'je-veux-préparer-ma-grossesse':
        content1 = `<p>Vous souhaitez <span class="Bold">mettre toutes les chances de votre côté pour tomber enceinte</span> et pour démarrer votre grossesse dans les meilleures conditions possibles.</p>`;
        content2 = `<p><span class="Bold">Très bonne idée !</span> Pour cela <span class="Bold"> nos experts vous recommandent le programme suivant: </span></p>`;
        break;
      case 'je-n-arrive-pas-a-tomber-enceinte':
        content1 = `<p>Vous essayez de <span class="Bold">tomber enceinte</span> depuis quelques mois et cela commence à vous paraître long.`;
        content2 = `<p>C'est effectivement <span class="Bold">le bon moment pour accélérer votre démarche !</span> Pour cela <span class="Bold">nos experts vous recommandent le programme suivant:</span></p>`;
        break;
      case 'j-ai-un-diagnostic-d-infertilite':
        content1 = `<p>Vous rencontrez des <span class="Bold">difficultés pour tomber enceinte</span> et êtes suivie médicalement pour cela.</p>`;
        content2 = `<p>Pour mieux compléter votre <span class="Bold"> suivi médical</span>, rien de mieux qu’une amélioration de vos habitudes alimentaires! Pour cela <span class="Bold">nos experts vous recommandent le programme suivant: </span> </p>`;
        break;
      default:
        throw new Error(`Unexpected value: ${baby_journey} `);
    }
  }

  return { content1, content2 };
}

export function getTagLabel(name, factor) {
  switch (name) {
    case TagNames.Weight:
      return factor ? 'Poids' : 'Perte de poids';
    case TagNames.Stress:
      return factor ? 'Stress' : 'Réduction du stress';
    case TagNames.Sleep:
      return factor ? 'Qualité de sommeil' : 'Meilleur sommeil';
    case TagNames.PhysicalActivity:
      return factor ? "Peu d'activité physique" : 'Activité physique';
    case TagNames.RelationshipToFood:
      return factor ? 'Comportement alimentaire' : "Rapport à l'alimentation";
    case TagNames.Smoker:
      return factor ? 'Tabac' : 'Réduction tabac';
    case TagNames.SmokingPartner:
      return 'Tabagisme passif';
    case TagNames.Sopk:
      return 'SOPK';
    case TagNames.Sugar:
      return factor ? 'Consommation de sucre' : 'Réduction du sucre';
    case TagNames.Age:
      return 'Vieillissement cellulaire';
    case TagNames.Environment:
      return 'Environnement pollué';
    case TagNames.Alcohol:
      return "Consommation d'alcool";
    case TagNames.Antiox:
      return factor ? 'Alimentation pauvre en antioxydant' : 'Antioxydant';
    case TagNames.Endometriose:
      return 'Endométriose';
    case TagNames.Omega3:
      return 'Alimentation pauvre en oméga-3';
    default:
      throw new Error(`Unexpected tag name: ${name} `);
  }
}

export function getFactorsString(tagList) {
  let content = '<ul>';
  for (const tag of tagList) {
    content += `<li>${getTagLabel(tag, true)}</li>`;
  }
  content += '</ul>';
  return content;
}

export function getOptionName(score) {
  let name = 'ESS';
  let plus = '';
  if (score.coaching >= 20) plus = '(+)';
  if (score.antiox >= 20) name = 'E-P';
  if (score.poids >= 20) name = 'E-S';
  if (score.poids >= 20 && score.antiox >= 20) name = 'E-PS';
  return { name, plus };
}

export function getProgramName(score) {
  let name = 'essentiel';
  if (score.antiox >= 20) name = 'antiox';
  if (score.antiox >= 20 && score.coaching >= 20) name = 'antiox_plus';
  if (score.poids >= 20) name = 'equilibre';
  if (score.poids >= 20 && score.coaching >= 20) name = 'equilibre_plus';
  if (score.coaching >= 20 && score.poids <20 && score.antiox <20) name = 'essentiel_plus';
  if (score.poids >= 20 && score.antiox >= 20) name = 'complet';
  if (score.poids >= 20 && score.antiox >= 20 && score.coaching >= 20) name = 'absolu';
  return name;
}

export function getOptionSentence(score) {
  let sentence = `les Essentiels`;
  if (score.antiox >= 20) {
    sentence = `les Essentiels et Spécificité + 35 ans`;
    if (score.coaching >= 20) {
      sentence = `les Essentiels et Spécificité + 35 ans`;
    }
  }
  if (score.poids >= 20) {
    sentence = `les Essentiels et Spécificité poids / SOPK `;
    if (score.coaching >= 20) {
      sentence = `les Essentiels et Spécificité poids / SOPK `;
    }
  }
  if (score.poids >= 20 && score.antiox >= 20) {
    sentence = `les Essentiels et Spécificité + 35 ans et Spécificité poids / SOPK `;
    if (score.coaching >= 20) {
      sentence = `les Essentiels et Spécificité + 35 ans, Spécificité poids / SOPK `;
    }
  }
  if (score.coaching >= 20) sentence += ` et Coaching+`;

  return sentence;
}

export function getDiagnosis(score, answers) {
  const oxydativeStressPercent = Math.round((100 * score.antiox) / 60);
  const hormonalDisorderPercent = Math.round((100 * score.poids) / 66);
  const lifeHigienePercent = Math.round((100 * score.coaching) / 98.5);

  let oxydativeStress = '<p>Nous n’avons pas identifié de risque de déséquilibre du stress oxydatif</p>';
  let hormonalDisorder = '<p>Nous ne détectons pas de risque de déséquilibre hormonal</p>';
  let lifeHigiene = '<p>Votre hygiène de vie semble satisfaisante</p>';

  let oxydativeStressDetails = '<p>Vous semblez ne pas subir du stress oxydatif</p>';
  let hormonalDisorderDetails = "<p>Vous semblez ne pas souffrir d'un dérèglement hormonal</p>";
  let lifeHigieneDetails =
    '<p>Vous semblez avoir une hygiène de vie satisfaisante pour démarrer un projet de grossesse</p>';
  let boostFertility = '';
  const age = parseInt(answers['age'], 10);
  const infertility_diagnosis = answers['infertility_diagnosis'];
  const smoker = answers['smoker'];
  const smoking_partner = answers['smoking_partner'];
  const environment = answers['environment'];
  const alcohol = answers['alcohol'];
  const weight = Number(answers['weight']);
  const height = Number(answers['height']);
  const imc = weight / ((height / 100) * (height / 100));
  const stress = parseInt(answers['stress'], 10);
  const sleep = parseInt(answers['sleep'], 10);
  const to_get_pregnant = answers['to_get_pregnant'];
  const relationship_to_food = answers['relationship_to_food'];

  if (score.coaching >= 20) {
    let coachingText;
    const coachingContent = [];
    if (
      relationship_to_food.includes('food_1') ||
      relationship_to_food.includes('food_2') ||
      relationship_to_food.includes('food_3')
    )
      coachingContent.push('Le grignotage');
    if (relationship_to_food.includes('food_4')) coachingContent.push('La consommation des aliments sucrés');
    if (stress >= 5) coachingContent.push('Le stress');
    if (sleep >= 5) coachingContent.push('La mauvaise qualité de sommeil');

    const coachingWord = coachingContent.length > 1 ? 'influencent' : 'influence';
    coachingText = coachingContent.join(', ');
    lifeHigiene = '<p>Une amélioration de votre hygiène de vie semblerait nécessaire<p>';
    lifeHigieneDetails = `<p><span class="Medium"> ${coachingText}</span> ${coachingWord} votre hygiène de vie, et donc votre fertilité. Pour les améliorer, nous vous conseillons une séance supplémentaire avec votre coach fertilité.</p>`;
  }

  if (score.antiox >= 20) {
    oxydativeStress =
      age >= 35
        ? '<p>Votre âge pourrait avoir un impact sur l’équilibre de votre stress oxydatif</p>'
        : '<p>Votre environnement de vie pourrait avoir un impact sur votre stress oxydatif</p>';
    let antioxText;
    const antioxContent = [];
    if (age >= 35) antioxContent.push('L’âge');
    if (smoker.includes('oui')) antioxContent.push('Le tabagisme');
    if (smoking_partner.includes('oui')) antioxContent.push('Le tabagisme passif');
    if (alcohol.includes('3-4') || alcohol.includes('5+')) antioxContent.push('La consommation d’alcool');
    if (environment.includes('oui')) antioxContent.push("L'environnement pollué");

    antioxText = antioxContent.join(', ');
    const antioxWord = antioxContent.length > 1 ? 'augmentent' : ' augmente';
    oxydativeStressDetails = `<p> <span class="Medium"> ${antioxText} </span> ${antioxWord} votre exposition au stress-oxydatif.Un désordre du stress oxydatif contribue à détériorer la qualité de vos ovules, à limiter votre capacité à concevoir, mais aussi favoriser les fausses couches.</p>`;
  }

  if (score.poids >= 20) {
    let poidsText;
    const poidsContent = [];
    hormonalDisorder = infertility_diagnosis.includes('sopk')
      ? '<p>Le SOPK pourrait avoir des conséquences sur votre équilibre hormonal</p>'
      : '<p>Le surpoids pourrait avoir des conséquences sur votre équilibre hormonal</p>';
    if (infertility_diagnosis.includes('sopk')) poidsContent.push('Le SOPK');
    if (imc >= 27 || to_get_pregnant.includes('weight')) poidsContent.push('Le Surpoids');
    poidsText = poidsContent.join(', ');
    const poidsWord = poidsContent.length > 1 ? 'peuvent' : ' peut';

    hormonalDisorderDetails = `<p><span class="Medium"> ${poidsText} </span> ${poidsWord} contribuer à un dérèglement hormonal conduisant à une absence d’ovulation chronique, un dysfonctionnement de vos ovaires et même à une augmentation du risque de complications pendant la grossesse.</p>`;
  }
  if (score.poids < 20 && score.antiox < 20 && score.coaching < 20) {
    boostFertility = `<p> Pour préparer au mieux votre corps à la grossesse, un traitement composé de <span class="Medium">vitamines et antioxydants</span> essentiels à la fertilité vous serez recommandé. Une coach fertilité pourrait également vous accompagner sur <span class="Medium">le plan émotionnel</span> jusqu'à l'aboutissement de votre projet.</p> `;
  }

  return {
    oxydativeStress,
    hormonalDisorder,
    lifeHigiene,
    oxydativeStressDetails,
    hormonalDisorderDetails,
    lifeHigieneDetails,
    boostFertility,
    oxydativeStressPercent,
    hormonalDisorderPercent,
    lifeHigienePercent,
  };
}

export function getPercent(name) {
  const optionName = name.name + name.plus;

  const mapping = {
    ESS: 31,
    'ESS(+)': 1,
    'E-P': 30,
    'E-P(+)': 1,
    'E-S': 30,
    'E-S(+)': 3,
    'E-PS': 14,
    'E-PS(+)': 2,
  };
  return Object.prototype.hasOwnProperty.call(mapping, optionName) ? mapping[optionName] : 50;
}
