import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Footer from './footer';
import Banner from './banner';
import styles from './module/conversion-layout.module.scss';
import CartContext from '../context/CartContext';
/**
 * This layout is used in the BeMum funnel
 * It has a minimal Header
 */
const ConversionLayout = ({ children, banner }) => {
  const { cart } = useContext(CartContext);

  const program = cart.contents.program;

  const isAGE = program === 'age';
  const isSOPK = program === 'sopk';

  return (
    <div className={styles.wrapper}>
      <header>{isAGE || isSOPK ? <div className={styles.logo}></div> : <Link to="/" className={styles.logo} />}</header>

      <main style={{ maxWidth: '100vw', overflow: 'hidden' }}>{children}</main>

      <Footer />
    </div>
  );
};

ConversionLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ConversionLayout;
