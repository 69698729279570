/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import PropTypes from 'prop-types';
import React from 'react';
import '../scss/header.scss';
import Footer from './footer';
import Banner from './banner';
import Accompagnement from './static/acc';
import Reassurance from './static/rea';
import useFacebookConversionApi from '../hooks/useFacebookConversionApi';
import useLocalStorage from '../hooks/useLocalStorage';
import { useLocation } from '@reach/router';
import Navigation from './navigation/navigation';
import { TrustPilotBox } from 'gatsby-plugin-trustpilot-trustbox';

const Layout = ({ children, withBanner, extended, extendedReduced, ...props }) => {
  const location = useLocation();
  const [fertilityTestResults] = useLocalStorage('answers', null);
  /**
   * Set by google tag manager
   * Used by Facebook Business Manager to deduplicate events
   * @see https://www.facebook.com/business/help/823677331451951?id=1205376682832142
   */

  const [eventId] = useLocalStorage('eventId', null);

  useFacebookConversionApi(
    'PageView',
    location.href,
    fertilityTestResults?.email,
    fertilityTestResults?.firstname,
    fertilityTestResults?.phone,
    eventId
  );

  const shouldShowTrustPilotBox = location.pathname !== '/programme/';

  return (
    <>
      <Navigation />

      {/* {withBanner && <Banner />} */}

      <main className={`Main`}>{children}</main>

      {shouldShowTrustPilotBox && (
        <div className={`truspilot_box`}>
          <TrustPilotBox
            locale="fr-FR"
            defaultRevueURL="https://fr.trustpilot.com/review/bemum.co"
            minReviewCount="10"
          />
        </div>
      )}

      {extended && (
        <>
          <div className="mtmd3">
            <Accompagnement />
          </div>
          <Reassurance />
        </>
      )}

      {extendedReduced && <Reassurance />}

      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
